import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray',
  standalone: true,
})
export class ToArrayPipe implements PipeTransform {
  public transform(value) {
    const keys = [];
    for (const key in value) {
      keys.push(value[key]);
    }
    return keys;
  }
}
